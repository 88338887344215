import React, { useEffect, useState, useRef } from "react";
import "./Header.less";
import useMyNav from "../../../router/nav";

const list = [
  {
    name: "首页",
    path: "/home",
  },
  {
    name: "博客列表",
    path: "/blog-list",
  },
  {
    name: "技能标签",
    path: "",
  },
  {
    name: "简历",
    path: "/resume",
  },
];

function Header({ isHide }: { isHide?: boolean }) {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileOpen(false);
      }
    };

    handleResize(); // 立即执行一次
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { go } = useMyNav();
  const headerRef = useRef(null);
  const hamburgerRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileOpen((prev) => !prev);
  };

  const goTo = (path) => {
    if (!path) alert("暂未开放");
    go(path);
    setIsMobileOpen(false);
  };

  // 点击外部区域关闭导航
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!headerRef.current || !hamburgerRef.current) return;
      if (
        !headerRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMobileOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const sticky = document.getElementById("header");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle(
            "isSticky",
            entry.intersectionRatio < 1
          );
        });
      },
      { threshold: [1] }
    );
    observer.observe(sticky);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="header" id="header" ref={headerRef}>
      <div className="title">BLOG 荣</div>
      <div
        className="mobileHeader"
        onClick={toggleMobileMenu}
        ref={hamburgerRef}
      >
        <div className={`hamburger ${isMobileOpen ? "open" : ""}`} />
      </div>
      <div className={`headerNav ${isMobileOpen ? "mobileOpen" : ""}`}>
        {list.map((item) => (
          <p key={item.name} onClick={() => goTo(item.path)}>
            {item.name}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Header;
