const txt_list = [
  {
    name: "林清玄",
    text: "历经万般红尘劫，犹如凉风轻拂面。",
  },
  {
    name: "《夏目友人帐》",
    text: "我想成为一个温柔的人，因为曾经被温柔的人那样对待。",
  },
  {
    name: "何其芳",
    text: "从此始感到成人的寂寞，更喜欢梦中道路的迷离。",
  },
  {
    name: "唐珙",
    text: "醉后不知天在水，满船清梦压星河。",
  },
  {
    name: "张晓风",
    text: "阳光的酒调得很淡，却很醇，浅浅地斟在每一个杯形的小野花里。",
  },
  {
    name: "李娟",
    text: "时光的沙漏里，细沙流走的是光阴。淡淡檀香里，裊袅燃尽的是光阴。",
  },
  {
    name: "顾城",
    text: "花开如火，也如寂寞。",
  },
  {
    name: "李清照",
    text: "酴靡落尽，犹赖有梨花。",
  },
  {
    name: "汪曾祺",
    text: "一定要爱着点什么，恰似草木对光阴的钟情。",
  },
  {
    name: "林清玄",
    text: "心美一切皆美，情深万象皆深。",
  },
  {
    name: "纳兰容若",
    text: "被酒莫惊春睡重，赌书消得泼茶香，当时只道是寻常。",
  },
  {
    name: "蔡崇达",
    text: "愿所有梦见过远方的人，心有惊雷，生似静湖。",
  },
  {
    name: "《你要去相信，没有到不了的明天》",
    text: "这个世界。上没有不带伤的人，真正能治愈自己的，只有自己。",
  },
  {
    name: "陈继儒",
    text: "春光浓似酒，花故醉人，夜色澄如水，月来洗俗。",
  },
  {
    name: "纳兰性德",
    text: "吹到一片秋香，清辉了如雪。",
  },
  {
    name: "鲁迅",
    text: "人生得一 知己足矣，斯世，当同怀视之。",
  },
  {
    name: "《摆渡人》",
    text: "每一个灵魂都是独特的，都有各自的美德和过错。",
  },
  {
    name: "简湞",
    text: "世间，恒能引动我的，唯日月星辰之姿、 山川湖海之美。",
  },
  {
    name: "艾青",
    text: "人间没有永恒的夜晚，世界没有永恒的冬天。",
  },
  {
    name: "海德格尔",
    text: "人安静地生活，哪怕是静静地听着风声，亦能感受到诗意的生活。",
  },
  {
    name: "张可久",
    text: "山中何事?松花酿酒，春水煎茶。",
  },
  {
    name: "莫峻",
    text: "少年与爱永不老去，即便披荆斩棘，丢失怒马鲜衣。",
  },
  {
    name: "席慕蓉",
    text: "繁花落尽，我心中仍有花落的声音。一朵，一朵，在无人的山间轻轻飘落。",
  },
  {
    name: "《炎樱语》",
    text: "每一个蝴蝶都是从前的一朵花的灵魂，回来寻找它自己。",
  },
  {
    name: "丁立梅",
    text: "日子就是这么的庸常，却有细碎的事物，如太阳碎碎的光芒，洒落其上。",
  },
  {
    name: "迟子建",
    text: "我的医生就是清风流水，日月星辰。",
  },
  {
    name: "《素履之往》",
    text: "所谓无底深渊，下去，也是前程万里。",
  },
  {
    name: "林语堂",
    text: "人生在世，还不是有时笑笑人家，有时给人家笑笑。",
  },
  {
    name: "《玉楼春:春景》",
    text: "为君持酒劝斜阳，且向花间留晚照。",
  },
  {
    name: "陆苏",
    text: "容颜会老去，四季不会停。那些散碎在笔尖的光阴，寂静欢喜。",
  },
  {
    name: "罗振宇",
    text: "你喜欢岁月静好，其实现实是大江奔流。",
  },
  {
    name: "《银河英雄传说》",
    text: "我的征途是星辰大海。",
  },
  {
    name: "张晓风",
    text: "如果五月的花香有其源自，如果十二月的星光有其出发的处所，我知道,你便是从那里来的。",
  },
  {
    name: "北岛",
    text: "偶尔有一缕阳光漏进来，缓缓移动，那是最古老的时针。",
  },
  {
    name: "余光中",
    text: "在逆风里把握方向，做暴风雨中的海燕，做不改颜色的孤星。",
  },
  {
    name: "北岛",
    text: "我拿本书，在长椅",
  },
  {
    name: "北岛",
    text: "上晒太阳，心变得软软的，容易流泪，像个多愁善感的老头。",
  },
  {
    name: "阿多尼斯",
    text: "爱，是持续瞬间的永恒。恨，是仿佛永存的瞬间。",
  },
  {
    name: "林清玄",
    text: "再温柔平和宁静的落雨，也有把人浸透的威力。",
  },
  {
    name: "《一代宗师》",
    text: "叶底藏花一度， 梦里踏雪几回。",
  },
  {
    name: "余秋雨",
    text: "更羡慕街边咖啡座里的目光，只一闪，便觉得日月悠长、山河无恙。",
  },
];
export default txt_list;
