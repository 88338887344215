import React from "react";
import "./resume.less";
import Header from "../components/Header/Header";

function Resume() {
  return (
    <>
      <Header />
      <div className="resume-container">
        <div className="resume-header">
          <h1>廖声荣</h1>
          <div className="resume-info">
            <p>26岁 | 4年前端开发经验</p>
            <p>邮箱：14796743426@163.com</p>
            <p>
              博客：
              <a
                href="https://puppet.pages.dev/"
                target="_blank"
                rel="noreferrer"
              >
                https://puppet.pages.dev/
              </a>
            </p>
          </div>
        </div>

        <div className="resume-section">
          <h2>个人技能</h2>
          <ul>
            <li>熟练掌握 React/Vue2/Vue3/Taro 小程序的开发使用。</li>
            <li>熟练掌握 HTML/CSS/JavaScript/TypeScript 等常用语法。</li>
            <li>熟悉使用 Jest 编写 VM 层的单元测试。</li>
            <li>
              熟悉使用 Webpack、Vite
              工具做项目工程化，能不依赖官方脚手架搭建项目基础框架。
            </li>
            <li>熟练开发自定义 plugins 做自动化程序，以提高开发效率。</li>
            <li>熟练使用 Git 等常用操作和 Git Hooks 进行代码和自动化管理。</li>
            <li>
              熟练使用 ESLint 把控质量，使用 Prettier 统一代码风格，使用 Husky
              做提交规范。
            </li>
            <li>
              了解 React 源码，包括 Fiber
              对象、Scheduler、Reconciler、Commit、Diff 算法。
            </li>
          </ul>
        </div>

        <div className="resume-section">
          <h2>工作经历</h2>
          <div className="experience-item">
            <h3>深圳志远融通科技 - 前端开发工程师</h3>
            <p className="duration">2022.8 - 2024.9</p>
            <ul>
              <li>负责项目从0到1的独立开发搭建，组件/Hooks封装</li>
              <li>参与APP项目的开发</li>
              <li>
                技术栈：React、React Native、TypeScript、Jotai、Webpack、Jest
              </li>
            </ul>
          </div>
          <div className="experience-item">
            <h3>中电金信软件 - 前端开发工程师</h3>
            <p className="duration">2020.5 - 2022.8</p>
            <ul>
              <li>前期负责数据展示页开发</li>
              <li>后期负责需求分配、定制规范及图表组件封装</li>
              <li>技术栈：React、Umi、Echarts、Redux</li>
            </ul>
          </div>
        </div>

        <div className="resume-section">
          <h2>项目经历</h2>
          <div className="experience-item">
            <h3>8c Game 平台</h3>
            <p className="duration">技术栈：React + Typescript + Jotai</p>
            <ul>
              <li>
                该平台是给海外游戏做入口的h5页面，展示游戏部门的所开发的项目入口。涉及登录、注册、个人中心、个人钱包、游戏列表、游戏分类、游戏数据、游戏道具以及道具商城等。
              </li>
              <li>
                基于react-cli和webpack做基础搭建，环境变量注入、配置less、文件压缩、组件库按需加载等。开发自定义plugin，实现静态资源自动化枚举和自动化约定式路由，提高项目开发效率。
              </li>
              <li>
                为保证Mock数据的时效性，实现自动化更新Mock，简便流程至一条指令即可开启Mock环境。
              </li>
              <li>
                通过React.lazy、prefetch、splitChunks、Tree
                shaking等做项目加载优化，将首屏时间优化至1.9s~2.3s之间。
              </li>
              <li>
                利用fixBabelImports、source
                map、compression-webpack-plugin等优化项目体积至60%。
              </li>
              <li>
                使用thread-loader和cache、resolve.modules、noParse等配置项提高构建效率。
              </li>
              <li>
                对antd二次封装GameList、HorizontalTabs、CommonModal、FormModal等组件。
              </li>
            </ul>
          </div>

          <div className="experience-item">
            <h3>Wealth 基金平台</h3>
            <p className="duration">
              技术栈：React Native + Typescript + Nestjs + Jest
            </p>
            <ul>
              <li>
                保险公司推出的基金购买平台，app同时发布在iOS和Android的应用市场，对于不同国家开发对应的功能模块，使用前端BFF层做配置以区分和数据处理。
              </li>
              <li>
                参与使用React
                native开发IOS、Android和web平台，使用webpack中resolve.extensions配置项实现动态文件解析，以区分某些web的特殊组件和页面文件。
              </li>
              <li>
                为提高代码的可读性、可维护性以及方便书写unit
                test，模仿mvvm架构模式分割代码层次。
              </li>
              <li>
                利用lokalise和git pre
                commit实现双向国际化语言同步，以方便客户方修改国际化文案实现自动化文件同步。
              </li>
              <li>
                为减少页面请求以及定制化api（app/web、HK/SG），使用Nestjs +
                Graphql开发BFF层。
              </li>
              <li>
                配合后台使用双Token无感刷新模式，实现（web）多系统之间的单点登录。
              </li>
            </ul>
          </div>

          <div className="experience-item">
            <h3>Wealth Admin 后台管理系统</h3>
            <p className="duration">技术栈：Vue3 + Vite + Pinia</p>
            <ul>
              <li>
                针对保险的wealth项目的数据，开发的后台管理项目，以便查看/管理用户、收益、偏好等用户信息。
              </li>
              <li>
                增加页面配置统一管理页面形式，以区分是否需要keep-alive做路由状态缓存。
              </li>
              <li>二次封装Element UI基础组件，以统一项目组件的风格和使用。</li>
              <li>
                配置run dev:mock，利用vite-plugin-mock实现前端mock环境和数据。
              </li>
              <li>
                利用vite.config中rollupOptions.output.manualchunks做分包策略，以避免node_modules下的文件多次请求和多次打包。
              </li>
            </ul>
          </div>

          <div className="experience-item">
            <h3>Ko咖啡小程序</h3>
            <p className="duration">技术栈：Taro + Typescript</p>
            <ul>
              <li>
                给自动咖啡制作设备提供小程序点单功能，使用微信扫码进入小程序，具备基本的点单功能和模块。
              </li>
              <li>
                基于Taro完成项目基本搭建（pages/window/tabBar/permission等），利用Taro内置api，完成登录、支付和分享等功能。
              </li>
              <li>
                因小程序bundle体积问题，利用config
                subpackages配置实现主包加载和次包懒加载功能优化。
              </li>
              <li>
                利用Taro内置getCurrentPages和relauch来避开路由栈（max：10）的限制问题。
              </li>
              <li>
                使用web api中的IntersectionObserver实现咖啡列表页接口分页请求。
              </li>
            </ul>
          </div>

          <div className="experience-item">
            <h3>腾讯 Databrain 数据大脑</h3>
            <p className="duration">技术栈：Umijs + Typescript + Echarts</p>
            <ul>
              <li>
                腾讯共培项目，打造数据大脑平台，对 Mobile、PC、Console
                平台的游戏数据进行经济、舆论、情报分析。利用微前端技术，将其分为情报、经分、舆情、看板系统。
              </li>
              <li>
                基于开发业务需求封装一些公用的React hook，以便其他开发人员使用。
              </li>
              <li>
                因有大量的图表数据展示需要，基于Echarts封装常用的图表组件。
              </li>
              <li>
                利用umi locale配置对应的json文件和内置setLocale
                api，以实现多语言国际化。
              </li>
              <li>重构原有的页面，将逻辑部分进行抽离，封装成可复用组件。</li>
            </ul>
          </div>
        </div>

        <div className="resume-section">
          <h2>教育经历</h2>
          <div className="experience-item">
            <h3>西南科技大学</h3>
            <p className="duration">
              2022.03 - 2024.06 | 本科 | 计算机科学与技术
            </p>
            <ul>
              <li>
                主修课程：数据结构、操作系统、计算机组成原理、计算机网络、Web开发
              </li>
            </ul>
          </div>
          <div className="experience-item">
            <h3>南昌教育学院</h3>
            <p className="duration">2017.09 - 2020.07 | 大专</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resume;
